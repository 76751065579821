import React from 'react'
import { Modal } from 'antd'
import Auth from '../../auth/auth'

const Authentication = props => {
    return (
        <Modal
            title='Continue'
            wrapClassName='contribute-auth'
            visible={props.visible}
            closable={false}
            maskClosable={false}
            footer={null}>
            <p>To enter the Walnut Contributors Community, please authenticate yourself again.</p>
            <Auth />
        </Modal>
    )
}

export default Authentication

