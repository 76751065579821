import 'react-quill/dist/quill.snow.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactQuill from 'react-quill'
import ProcessImage from 'react-imgpro'
import moment from 'moment'
import { generateUploadId, generateVideoId } from './generate'
import { Input, Button, TimePicker, message } from 'antd'

export class QuillEditor extends Component {
    state = {
        uploadId: '',
        uploadedImage: '',
        uploadedImageFile: '',
        processedHeight: 0,
        processedWidth: 0,
        originalWidth: 0,
        video: '',
        videoId: '',
        start: 0,
        end: 60,
        addedVideo: false
    }

    componentDidMount() {
        const { videoObject } = this.props
        this.setState({ 
            uploadId: generateUploadId(), 
            videoId: generateVideoId(),
            video: videoObject ? videoObject.embed : '',
            start: videoObject ? videoObject.start : 0,
            end: videoObject ? videoObject.end : 60,
            addedVideo: videoObject ? true : false
        })
    }

    imageHandler = () => {
        document.getElementById(this.state.uploadId).click()
    }

    uploadImage = (e) => {
        const uploadedImageFile = e.target.files[0]

        if (uploadedImageFile.size > 500000) {
            message.error('File size cannot exceed 500kb.')
            return
        }
        if ((uploadedImageFile.type !== 'image/jpeg') && (uploadedImageFile.type !== 'image/png')) {
            message.error('You can only upload .jpg or .png files.')
            return
        }
        const uploadedImage = window.URL.createObjectURL(uploadedImageFile)

        message.loading('Processing image..', 0)

        const imageAPI = new Image()
        imageAPI.src = uploadedImage
        imageAPI.onload = function () {
            const height = imageAPI.height
            const width = imageAPI.width
            let ratio = 1
            if ((height > 600) || (width > 600)) {
                const heightRatio = height / 600
                const widthRatio = width / 600
                ratio = heightRatio > widthRatio ? heightRatio : widthRatio
            }
            this.setState({
                uploadedImage, 
                uploadedImageFile, 
                processedHeight: height / ratio,
                processedWidth: width / ratio,
                originalWidth: width
            })
        }.bind(this)
    }

    insertImage = (value, width) => {
        const range = this.quill.getEditor().getSelection()
        this.quill.getEditor().insertEmbed(range.index, 'image', value, 'user')
        this.quill.getEditor().formatText(range.index, 1, 'width', `${width}px`)
        this.setState({ uploadedImage: '', uploadedImageFile: '', processedHeight: 0, processedWidth: 0, originalWidth: 0 })
        message.destroy()
    }

    handleVideo = value => {
        this.setState({ video: value })
    }

    insertVideo = (videoObject, newStart, newEnd) => {
        const { video, start, end } = this.state
        const videoEmbed = video || videoObject.embed
        if (!videoEmbed) return
        const YouTubeId = this.findYouTubeId(videoEmbed)
        if (!YouTubeId) {
            message.error('Cannot find YouTube ID from URL.')
            return
        }
        if ((newStart && isNaN(newStart)) || (newEnd && isNaN(newEnd))) return
        let startSeconds = newStart === false ? start : newStart // newStart can be 0
        let endSeconds = newEnd === false ? end : newEnd
        const embed = `https://www.youtube.com/embed/${YouTubeId}?start=${startSeconds}&end=${endSeconds}&rel=0&iv_load_policy=1&modestbranding=1&playsinline=1`
        this.quill.getEditor().setContents([], 'user')
        this.quill.getEditor().insertEmbed(0, 'video', embed, 'user')
        this.setState({ addedVideo: true })
    }

    findYouTubeId = (URL) => {
        const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
        return URL.match(regex) ? URL.match(regex)[1] : false
    }

    handleTime = (videoObject, value, type) => {
        const seconds = moment(value, 'H:mm:ss').diff(moment().startOf('day'), 'seconds')
        if (type === 'start') {
            this.setState({ start: seconds })
            this.insertVideo(videoObject, seconds, false)
        }
        if (type === 'end') {
            this.setState({ end: seconds })
            this.insertVideo(videoObject, false, seconds)
        }
    }

    render() {
        const { uploadId, uploadedImage, uploadedImageFile, processedHeight, processedWidth, originalWidth, videoId, start, end, addedVideo } = this.state
        const { type, placeholder, value, onChange, videoObject, isComposeOption } = this.props
        
        const startMomemt = moment("1900-01-01 00:00:00").add(start, 'seconds').format('H:mm:ss')
        const endMomemt = moment("1900-01-01 00:00:00").add(end, 'seconds').format('H:mm:ss')

        const valueProp = isComposeOption ? { value } : { defaultValue: value } // Compose options need to use the 'value' attribute

        if (type === 'content') return (
            <div className="quill-container">
                <ReactQuill
                    {...valueProp}
                    className="quill-editor"
                    ref={(q) => this.quill = q}
                    placeholder={placeholder}
                    onChange={onChange}
                    formats={[
                        'bold', 'italic', 'strike', 'list', 'bullet', 'indent',
                        'header', 'align', 'image', 'width'
                    ]}
                    modules={{
                        toolbar: {
                            container: [
                                [{ 'header': '1' }, { 'align': 'center' }, 'image']
                            ],
                            handlers: {
                                'image': this.imageHandler
                            }
                        },
                        clipboard: {
                            matchVisual: false,
                        }
                    }}
                />
                <div style={{ display: 'none' }}>
                    <input type="file" accept="image/*" id={uploadId} onChange={this.uploadImage} />
                    {uploadedImage &&
                        <ProcessImage
                            image={uploadedImage}
                            resize={{ height: processedHeight, width: processedWidth, mode: 'bicubic' }}
                            processedImage={(source, error) => {
                                if (error) message.error('There was an error. Please refresh and try again.')
                                else {
                                    const halfWidth = originalWidth / 2 // For displaying crisp images on mobile
                                    const imageWidth = Math.min(halfWidth, processedWidth)                                    
                                    this.insertImage(source, imageWidth)
                                }
                                window.URL.revokeObjectURL(uploadedImageFile)
                            }}
                        />
                    }
                </div>
            </div>
        )
        else if (type === 'video' && videoId) return (
            <div>
                <div id={videoId} className="video-toolbar">
                    <Input
                        defaultValue={videoObject ? videoObject.embed : ''}
                        placeholder="YouTube URL"
                        size="large"
                        onChange={(e) => this.handleVideo(e.target.value)}
                        addonAfter={<Button className="ql-insertVideo embed-button">Embed</Button>}
                    />
                </div>
                <ReactQuill
                    className={`quill-editor ${!addedVideo && 'empty'}`}
                    ref={(q) => this.quill = q}
                    defaultValue={value}
                    onChange={onChange}
                    formats={['video']}
                    modules={{
                        toolbar: {
                            container: `#${videoId}`,
                            handlers: {
                                insertVideo: this.insertVideo
                            }
                        }
                    }}
                />
                <div className="timeframe">
                    <span>Start</span>
                    <TimePicker 
                        onChange={(value) => this.handleTime(videoObject, value, 'start')}
                        defaultValue={moment(startMomemt, 'H:mm:ss')} 
                        disabled={!addedVideo} allowClear={false} 
                        size="large" format='H:mm:ss' />
                    <span>End</span>
                    <TimePicker 
                        onChange={(value) => this.handleTime(videoObject, value, 'end')}
                        defaultValue={moment(endMomemt, 'H:mm:ss')} 
                        disabled={!addedVideo} allowClear={false} 
                        size="large" format='H:mm:ss' />
                </div>
            </div>
        )
        else if (type === 'text') return (
            <div>
                <ReactQuill
                    theme="bubble"
                    className="quill-editor"
                    ref={(q) => this.quill = q}
                    placeholder={placeholder}
                    defaultValue={value}
                    onChange={onChange}
                    formats={[
                        'bold', 'italic', 'link', 'list', 'bullet'
                    ]}
                    modules={{
                        toolbar: {
                            container: [
                                ['link', 'bold', 'italic']
                            ]
                        },
                        clipboard: {
                            matchVisual: false,
                        }
                    }}
                />
            </div>
        )
        else return <div />
    }
}

export default connect()(QuillEditor)
