export const generateSectionId = () => {
    return 'section-' + Math.random().toString(36).substr(2, 9)
}

export const generateCardId = () => {
    return 'card-' + Math.random().toString(36).substr(2, 9)
}

export const generateUploadId = () => {
    return 'upload-' + Math.random().toString(36).substr(2, 9)
}

export const generateVideoId = () => {
    return 'video-' + Math.random().toString(36).substr(2, 9)
}
