export const processCharacters = items => {
    if (!items) return
    const combined = items.reduce((a, b) => { return { items: a.items.concat(b.items) } })
    const itemsArray = combined.items.filter(item => item.type === 'character')
    const itemsObject = itemsArray.reduce((items, item) => Object.assign(items, { [item.name]: item }), {}) 
    return itemsObject
}

export const processItems = items => {
    if (!items) return
    const combined = items.reduce((a, b) => { return { items: a.items.concat(b.items) } })
    const itemsArray = combined.items.filter(item => item.type === 'collection')
    const itemsObject = itemsArray.reduce((items, item) => Object.assign(items, { [item.id]: item }), {}) 
    return itemsObject
}

export const processItemsToArray = items => {
    if (!items) return
    const combined = items.reduce((a, b) => { return { items: a.items.concat(b.items) } })
    const itemsArray = combined.items.filter(item => item.type === 'collection')
    return itemsArray
}

